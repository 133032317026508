import React, { type ReactNode, createContext } from 'react'
import { GenreTree } from '~/services/GenreService'
import { ProgramData } from '~/services/ProgramService.server'

export interface IServerContext {
  genreTreeById: Record<number, GenreTree>
  genreTreeOrdered: GenreTree[]
  programsById: Record<number, ProgramData>
}

export const ServerContext = createContext<IServerContext>({
  genreTreeById: {},
  genreTreeOrdered: [],
  programsById: {},
})

export const ServerContextProvider: React.FC<{
  children: ReactNode
  genreTreeById: Record<number, GenreTree>
  genreTreeOrdered: GenreTree[]
  programsById: Record<number, ProgramData>
}> = ({ children, genreTreeById, genreTreeOrdered, programsById }) => {
  return (
    <ServerContext.Provider
      value={{
        genreTreeById,
        genreTreeOrdered,
        programsById,
      }}
    >
      {children}
    </ServerContext.Provider>
  )
}
